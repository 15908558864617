import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Fold from "../components/fold"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const GameFold = props => {
  const {
    androidLink,
    appStoreLink,
    colorBar,
    description,
    direction = "left",
    iconSrc,
    splashSrc,
    title,
  } = props

  return (
    <Fold
      colorBar={colorBar}
      style={
        {
          // scrollSnapAlign: "end",
        }
      }
    >
      <div className="mobile">
        <div className="splash-app-title">{title}</div>
      </div>

      <div style={{ display: "flex" }} className={`splash-${direction}`}>
        <Img fluid={splashSrc} className="mobile splash-image" />
        <div style={{ flex: 1 }} className="desktop splash-image-desktop">
          <div style={{ position: "relative", width: "50%" }}>
            <div style={{ width: "100%", textAlign: "right" }}>
              <Img fluid={splashSrc} className="splash-image" />
            </div>
            <div
              className="desktop"
              style={{
                position: "absolute",
                right: -50,
                bottom: -10,
              }}
            >
              <Img fixed={iconSrc} />
            </div>
          </div>
        </div>
        <div
          className="desktop"
          style={{
            flex: 1,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              height: "100vh",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              className="splash-description-desktop"
              style={{ margin: "0 3vw" }}
            >
              <h2>{title}</h2>
              <p
                style={{
                  fontSize: "2rem",
                  fontWeight: 600,
                  width: 335,
                }}
              >
                {description}
                <div style={{ paddingTop: "1rem" }}>
                  {appStoreLink && (
                    <a
                      className="appstore-link"
                      href={appStoreLink}
                      style={{
                        marginTop: 5,
                        marginRight: 5,
                      }}
                    >
                      <img
                        src="./icon-ios.png"
                        alt="Download on the App Store"
                      />
                    </a>
                  )}
                  {androidLink && (
                    <a
                      className="appstore-link"
                      href={androidLink}
                      style={{
                        marginTop: 5,
                      }}
                    >
                      <img
                        src="./icon-android.png"
                        alt="Download on the App Store"
                      />
                    </a>
                  )}
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ position: "absolute", bottom: 0, height: 260 }}>
        <div className={`the-reverse-trapezoid ${direction}`} />
        <div
          className="mobile"
          style={{
            position: "absolute",
            color: "#3e3e3e",
            fontSize: "1.6rem",
            fontWeight: 600,
            width: "88vw",
            margin: "40px 6vw 6vw 6vw",
            ...(direction === "right" && { textAlign: "right" }),
          }}
        >
          <Img fixed={iconSrc} />
          <p
            className={`splash-description-mobile ${direction}`}
            style={{
              maxWidth: 400,
            }}
          >
            {description}
            <br />
            <div style={{ textAlign: "center" }}>
              {appStoreLink && (
                <a
                  className="appstore-link"
                  href={appStoreLink}
                  style={{
                    marginTop: 5,
                    marginRight: 5,
                  }}
                >
                  <img src="./icon-ios.png" alt="Download on the App Store" />
                </a>
              )}
              {androidLink && (
                <a
                  className="appstore-link"
                  href={androidLink}
                  style={{
                    marginTop: 5,
                  }}
                >
                  <img
                    src="./icon-android.png"
                    alt="Download on the App Store"
                  />
                </a>
              )}
            </div>
          </p>
          {/* <a href={href}>Learn More</a> */}
        </div>
      </div>
    </Fold>
  )
}

const AppPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Apps" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="trapezoid"></div>
        <Fold>
          <div
            style={{
              maxWidth: 1200,
              margin: "0 auto",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="page-quote"
              style={{
                padding: "6vw",
                maxWidth: "100%",
                flex: 1,
                maxHeight: "25vh",
              }}
            >
              The only way to do great work
              <br />
              is to love what you do.
              <br />– Steve Jobs
            </div>
            <div className="tag-line">
              <h1 style={{}}>
                We Love
                <br />
                Making Apps.
              </h1>
            </div>
            <div
              style={{
                // width: "97vw",
                paddingRight: "6vw",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flex: 1,
              }}
            >
             {/*
              //   <p
              //   className="post-content-description"
              //   style={{
              //     fontWeight: 400,
              //     textAlign: "right",
              //     // width: "45%",
              //   }}
              // >
              //   While most of our development time is spent working on apps for
              //   other companies, sometimes it’s more fun to just make our own.
              //   Explore some of the projects we’ve made by our own commission:
              // </p>
                */}
            </div>
          </div>
        </Fold>
        {/*
        // <GameFold
        //   splashSrc={data.solitaire_one.childImageSharp.fluid}
        //   title="Solitaire One"
        //   androidLink="https://cdn.skillz.com/devportal2/uploads/game_binary/file/17154/SolitaireOneByParkerAndLeigh-Skillz1612426342.apk"
        //   iconSrc={[
        //     data.solitaire_one_icon_mobile.childImageSharp.fixed,
        //     {
        //       ...data.solitaire_one_icon_desktop.childImageSharp.fixed,
        //       media: `(min-width: 850px)`,
        //     },
        //   ]}
        //   description="Another take on a great classic, compete against others in this single draw version of solitaire. "
        //   href=""
        //   colorBar={[
        //     { color: "#F7B402", width: "30vw" },
        //     { color: "#E63146", width: "70vw" },
        //   ]}
        // />

        // <GameFold
        //   splashSrc={data.solitaire.childImageSharp.fluid}
        //   title="Solitaire"
        //   androidLink="https://cdn.skillz.com/devportal2/uploads/game_binary/file/17152/SolitaireByParkerAndLeigh-Skillz1612425255.apk"
        //   iconSrc={[
        //     data.solitaire_icon_mobile.childImageSharp.fixed,
        //     {
        //       ...data.solitaire_icon_desktop.childImageSharp.fixed,
        //       media: `(min-width: 850px)`,
        //     },
        //   ]}
        //   direction="right"
        //   description="Compete against other players with the same deck while showcasing your skills in this classic 3 card draw solitaire."
        //   href=""
        //   colorBar={[
        //     { color: "#558F4D", width: "70vw" },
        //     { color: "#4D60E0", width: "30vw" },
        //   ]}
        // />
        // <GameFold
        //   splashSrc={data.boxes.childImageSharp.fluid}
        //   title="Boxes"
        //   appStoreLink="https://apps.apple.com/us/app/boxes-by-parker-and-leigh/id1481386277?uo=4&pt=118725247&ct=pl_apps"
        //   iconSrc={[
        //     data.boxes_icon_mobile.childImageSharp.fixed,
        //     {
        //       ...data.boxes_icon_desktop.childImageSharp.fixed,
        //       media: `(min-width: 850px)`,
        //     },
        //   ]}
        //   description="Race the clock to eliminate all the colors on the board. Boxes is a challenging puzzle game of swiping and matching."
        //   href=""
        //   colorBar={[
        //     { color: "#2957CC", width: "30vw" },
        //     { color: "#F38A03", width: "70vw" },
        //   ]}
        // />
        */}
        {/* <Fold
          // colorBar={[
          //   { color: "#292929", width: "70vw" },
          //   { color: "#B4B4B4", width: "30vw" },
          // ]}
          >
          {" "}
        </Fold> */}
      </article>
      <div
        style={{
          overflow: "auto",
          margin: "4rem",
          fontWeight: 700,
        }}
      >
        <p style={{ textAlign: "center" }}>
          We also maintain a list of other Skillz enabled games{" "}
          <Link to="/skillzgames">here</Link>.
        </p>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    boxes: file(relativePath: { eq: "splashscreens/boxes.png" }) {
      childImageSharp {
        fluid(maxWidth: 1294) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    boxes_icon_mobile: file(
      relativePath: { eq: "splashscreens/boxes_icon.png" }
    ) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    boxes_icon_desktop: file(
      relativePath: { eq: "splashscreens/boxes_icon.png" }
    ) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    solitaire: file(relativePath: { eq: "splashscreens/solitaire.png" }) {
      childImageSharp {
        fluid(maxWidth: 1294) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    solitaire_icon_mobile: file(
      relativePath: { eq: "splashscreens/solitaire_icon.png" }
    ) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    solitaire_icon_desktop: file(
      relativePath: { eq: "splashscreens/solitaire_icon.png" }
    ) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    solitaire_one: file(
      relativePath: { eq: "splashscreens/solitaire_one.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1294) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    solitaire_one_icon_mobile: file(
      relativePath: { eq: "splashscreens/solitaire_one_icon.png" }
    ) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    solitaire_one_icon_desktop: file(
      relativePath: { eq: "splashscreens/solitaire_one_icon.png" }
    ) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AppPage location={props.location} data={data} {...props} />
    )}
  />
)
