import React from "react"

export default props => {
  const { color, children, colorBar, style } = props

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100vw",
        backgroundColor: color,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
    >
      {children}
      {colorBar && (
        <div
          className="desktop desktop-flex"
          style={{
            position: "absolute",
            top: 0,
            height: 10,
            width: "100vw",
            // display: "flex",
          }}
        >
          {colorBar.map((item, index) => (
            <div
              key={item.color + index}
              style={{
                height: "100%",
                backgroundColor: item.color,
                width: item.width,
              }}
            ></div>
          ))}
        </div>
      )}
    </div>
  )
}
